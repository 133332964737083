@import './styles/main.less';

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 10px; // Note: base for Trybe
}

span.total_amount {
  color: @text-90;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

:root {
  --border-width: 1px;
  --checkbox-size: 1.6rem;
}

@media screen and (min-width: 3456px) {
  :root {
    --border-width: 2px;
  }

  html {
    font-size: 15px;
  }
}

.hidden {
  display: none !important;
}

.ant-modal-body {
  span:not(.anticon) {
    font-size: unset !important;
    color: unset !important;
  }
}

.ant-input {
  height: unset !important;
}
